export default {
  public: [],
  admin: [
    // 簡訊列表
    {
      path: 'sms',
      name: 'sms',
      component: () => import('@/modules/sms/views/smsList.vue'),
    },

    // 簡訊表單
    {
      path: 'sms/create',
      name: 'sms-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/sms/views/smsForm.vue'),
    },
    {
      path: 'sms/update/:target',
      name: 'sms-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/sms/views/smsForm.vue'),
    },

    {
      path: 'sms-debug',
      name: 'sms-debug',
      component: () => import('@/modules/sms/views/smsDebug.vue'),
    },
  ],
}
